<template>
  <div>
        <i-table stripe  :data="list" :columns="titles"></i-table>

        <div class="paging_style">
            <Page size="small" show-total show-elevator :current="query.pageNumber" :page-size="query.pageSize" :total="total" @on-change="handlePageNumberChange"></Page>
        </div>
  </div>
</template>

<script>
import { getCustomerPage, deleteCustomer } from '@/api/scp/myfirstparty'
import { formatInvoiceType } from '@/utils/tagStatus'

export default {
  data () {
    return {
      list: [],
      titles: [
        { title: '甲方名称', key: 'name' },
        { title: '所属客户', key: 'advertiserName' },
        {
          title: '发票类型',
          key: 'invoiceTypeName',
          align: 'center',
          render: (h, data) => {
            return formatInvoiceType(h, data.row.invoiceType, data.row.invoiceTypeName)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            return h('div', this.allowEdit ? [
              h('a', {
                on: {
                  click: () => {
                    this.handleEdit(data.row)
                  }
                }
              }, '编辑'),
              h('a', {
                style: {
                  'margin-left': '5px',
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.handleDelete(data.row)
                  }
                }
              }, '删除')

            ] : [])
          }
        }

      ],
      query: { pageNumber: 1, pageSize: 15 },
      total: 0
    }
  },
  methods: {
    getPage () {
      getCustomerPage(this.query).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    reloadData () {
      this.query.pageNumber = 1
      this.getPage()
    },
    handleEdit (firstParty) {
      this.$emit('on-detail', firstParty.id)
    },
    handleDelete (firstparty) {
      this.$Modal.confirm({
        title: '确认操作',
        content: '点击确认后将删除该甲方，是否确认？',
        onOk: () => {
          deleteCustomer({ id: firstparty.id }).then(res => {
            if (res && !res.errcode) {
              this.query.pageNumber = 1
              this.getPage()
              this.$emit('on-updated', firstparty.id)
              this.$Notice.success({ desc: '删除成功' })
            }
          })
        }
      })
    },
    handlePageNumberChange (page) {
      this.query.pageNumber = page
      this.getPage()
    }
  },
  created () {
    this.getPage()
  },
  computed: {
    beginUpdate () {
      return this.$store.state.advertiser.beginUpdate
    },
    allowEdit () {
      return this.isAuth('FirstParty_Edit')
    }
  },
  watch: {
    beginUpdate (val) {
      this.getPage()
    }
  }

}
</script>
